import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import vueHashCalendar from 'vue-hash-calendar'
import 'font-awesome/css/font-awesome.min.css'
import { getWxApiToken } from '@api/wx.js'
import { checkUserIsActive } from '@api/login.js'
import { init } from '@/init'
// 加载动态设置REM基准值
import 'amfe-flexible'
// 加载vant核心组件库
import Vant from 'vant'
// 加载vant全局样式
import 'vant/lib/index.css'

// 引入全局的css样式
import './styles/index.less'
// 引入图库
import './icons'

import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

//  加载日历全局样式
import 'vue-hash-calendar/lib/vue-hash-calendar.css'

import { Lazyload, Toast } from 'vant'
// import { Toast } from 'vant'
import '@vant/touch-emulator'
Vue.use(Lazyload, {
  error: require('@assets/img/loading-error.png'),
  loading: require('@assets/img/loading-ing.png')
})

// window.signremark = '重要提示：请认真核对本月出勤时间、加班时间、工资数额、扣款等全部工资结构名目，确认无误后签字。公司按员工签字确认后的金额支付工资，即为已按时足额发放全部工资福利，所有扣款名目已经员工核对无异议，双方不存在任何工资福利等款项的克扣、拖欠或争议。'
// 注册使用vant组件库
Vue.use(Vant)
// 注册日历组件
Vue.use(vueHashCalendar)
Vue.config.productionTip = false
//jssdk 开放 wx-open-launch-weapp 跳转小程序标签
Vue.config.ignoredElements = ['wx-open-launch-weapp']
let $version = document.getElementById('index_webpack_version').value
Vue.prototype.$version = $version.replace('BEGIN_VERSION_', '')
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  // console.log('beforeEach')
  document.title = `${i18n.t('module.title')}`
  let token = sessionStorage.getItem('token')
  if (!token || token == null || token == '')
  {
    getWxApiToken().then(result => {
      sessionStorage.setItem('token', result)
      // 登录后是否重定向到指定页面 如果需要重定向 meta的redirect设置为true
      if (typeof to.meta.redirect != 'undefined')
      {
        if (to.meta.redirect)
        {
          localStorage.setItem('V8_REDIRECT_PATH', to.fullPath)
          localStorage.setItem('userInfo', '')
        }
      }
      if (to.path == '/login')
      {
        next()
      } else
      {
        // 页面是否需要登录访问 如果不需要 meta的nologin设置为true
        if (typeof to.meta.nologin != 'undefined')
        {
          if (to.meta.nologin)
          {
            next()
          } else
          {
            let userInfo = localStorage.getItem('userInfo')
            if (!userInfo)
            {
              next('/login')
            } else
            {
              checkUserIsActive({
                username: JSON.parse(userInfo).username,
                pwd: localStorage.getItem('v8_login_pwd'),
                // langid: localStorage.getItem('langid') || 0,
                // openid: ''
              }).then(res => {
                if (res == '1')
                {
                  next()
                } else
                {
                  next('/login')
                }
              })
            }
          }
        } else
        {
          let userInfo = localStorage.getItem('userInfo')
          if (!userInfo)
          {
            next('/login')
          } else
          {
            checkUserIsActive({
              username: JSON.parse(userInfo).username,
              pwd: localStorage.getItem('v8_login_pwd'),
              // langid: localStorage.getItem('langid') || 0,
              // openid: ''
            }).then(res => {
              if (res == '1')
              {
                next()
              } else
              {
                next('/login')
              }
            })
          }
        }
      }
    })
  } else
  {
    if (to.path == '/home' && window.V3IsClound)
    {
      location.href =
        window.V3url + '/weixin/index.html?state=' + window.CloundEntid
    } else if (to.path == '/login')
    {
      next()
    } else
    {
      // 页面是否需要登录访问 如果不需要 meta的nologin设置为true
      if (typeof to.meta.nologin != 'undefined')
      {
        if (to.meta.nologin)
        {
          next()
        } else
        {
          let userInfo = localStorage.getItem('userInfo')
          if (!userInfo)
          {
            next('/login')
          } else
          {
            checkUserIsActive({
              username: JSON.parse(userInfo).username,
              pwd: localStorage.getItem('v8_login_pwd'),
              // langid: localStorage.getItem('langid') || 0,
              // openid: ''
            }).then(res => {
              if (res == '1')
              {
                next()
              } else
              {
                next('/login')
              }
            })
          }
        }
      } else
      {
        let userInfo = localStorage.getItem('userInfo')
        if (!userInfo)
        {
          next('/login')
        } else
        {
          checkUserIsActive({
            username: JSON.parse(userInfo).username,
            pwd: localStorage.getItem('v8_login_pwd'),
            // langid: localStorage.getItem('langid') || 0,
            // openid: ''
          }).then(res => {
            if (res == '1')
            {
              next()
            } else
            {
              next('/login')
            }
          })
        }
      }
    }
    // else {
    //   next()
    // }
  }
  // if (window.ispc) {
  //   if (to.path == '/jump') {
  //     //  针对跳转到外部网页，不需要限制宽度
  //     document.getElementById('APP_MAIN').className = 'pc-container2' //设置为新的
  //   } else {
  //     document.getElementById('APP_MAIN').className = 'pc-container' //设置为新的
  //   }
  // }
})
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
let token = sessionStorage.getItem('token')
if (!token || token == null || token == '')
{
  getWxApiToken().then(result => {
    sessionStorage.setItem('token', result)
  })

  if (process.env.NODE_ENV === 'production')
  {
    init(() => {
      new Vue({
        router,
        i18n,
        store,

        render: h => h(App)
      }).$mount('#app')
    })
  } else
  {
    new Vue({
      router,
      i18n,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
} else
{
  if (process.env.NODE_ENV === 'production')
  {
    init(() => {
      new Vue({
        router,
        i18n,
        store,
        render: h => h(App)
      }).$mount('#app')
    })
  } else
  {
    new Vue({
      router,
      i18n,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
}

Vue.prototype.$getObject = function (v) {
  let s = Object.prototype.toString.call(v)
  if (s == '[object Object]')
  {
    return 'Object'
  } else if (s == '[object Null]')
  {
    return 'Null'
  } else if (s == '[object Number]')
  {
    return 'Number'
  } else if (s == '[object Boolean]')
  {
    return 'Boolean'
  } else if (s == '[object String]')
  {
    return 'String'
  } else if (s == '[object Array]')
  {
    return 'Array'
  } else if (s == '[object Date]')
  {
    return 'Date'
  } else if (s == '[object Function]')
  {
    return 'Function'
  } else if (s == '[object Undefined]')
  {
    return 'Undefined'
  } else
  {
    return s
  }
}